import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import "./navbar.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import LogoImage from "../../Images/garvlogo.jpeg";
import { Image } from "@mui/icons-material";
import { Box } from "@mui/material";
import LogoIcon from "../Icons/Logo";


const NavBar = () => {
  const { cartList } = useSelector((state) => state.cart);
  const [expand, setExpand] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 100) {
      setIsFixed(true);
    } else if (window.scrollY <= 50) {
      setIsFixed(false);
    }
  }
  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      fixed="top"
      expand="md"
      className={isFixed ? "navbar fixed" : "navbar"}
      expanded={expand}
    >
      <Container className="navbar-container" style={{ padding: "5px 0" }}> {/* Adjusted padding */}
      <Navbar.Brand as={Link} to="/" style={{ display: 'flex', alignItems: 'center' }}>
      <LogoIcon  />
            <h1 className="logo">Viraj Auto</h1>
</Navbar.Brand>


        <div className="d-flex">
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpand(!expand)}
          >
            <span></span>
            <span></span>
            <span></span>
          </Navbar.Toggle>
        </div>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end flex-grow-1 pe-3">
            <Nav.Item>
              <Link
                className="navbar-link"
                to="/"
                onClick={() => setExpand(false)} // Close navbar on click
              >
                <span className="nav-link-label">Home</span>
              </Link>
            </Nav.Item>

            <Nav.Item>
              <Link
                className="navbar-link"
                to="/Vehicles"
                onClick={() => setExpand(false)} // Close navbar on click
              >
                <span className="nav-link-label">Vehicles</span>
              </Link>
            </Nav.Item>

            <Nav.Item>
              <Link
                className="navbar-link"
                to="/sell"
                onClick={() => setExpand(false)} // Close navbar on click
              >
                <span className="nav-link-label">Sell</span>
              </Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
