import { lazy, Suspense, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Loader from "./components/Loader/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Alert, Snackbar } from "@mui/material";
import { CacheProvider } from "@emotion/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import SnackbarProvider from "src/components/SnackbarProvider/SnackbarProvider";
import { Provider } from "react-redux";
import createEmotionCache from "src/createEmotionCache";

const clientSideEmotionCache = createEmotionCache();
const queryQlient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const Home = lazy(() => import("./pages/Home"));
const Vehicles = lazy(() => import("./pages/Vehicles"));
const Cart = lazy(() => import("./pages/Cart"));
const Sell = lazy(() => import("./pages/SellCar"));
const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const Product = lazy(() => import("./pages/Product"));
const BrandProduct = lazy(() => import("./pages/BrandProduct"));
const CarDetails = lazy(() => import("./pages/CarDetails"));

function App(props) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  function handleClose() {
    setIsOpen(false);
    setSnackbarMsg("");
  }
  const handleCloseForm = () => {
    setShowForm(false);
  };
  return (
    <CacheProvider value={emotionCache}>
      {/* <Provider> */}
      <QueryClientProvider client={queryQlient}>
        {/* <CurrentServiceProvider> */}
        <SnackbarProvider>
          {/* <ThemeProvider
            theme={
              isDashboardRoute ? dashboardTheme : nonDashboardTheme
            }
          > */}
          {/* <Component {...pageProps} /> */}
          {/* </ThemeProvider> */}
          <Suspense fallback={<Loader />}>
            <Router>
              <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              <NavBar />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/vehicles" element={<Vehicles />} />
                <Route path="/car/:bodytype" element={<BrandProduct />} />
                <Route path="/cardetails/:id" element={<Product />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/sell" element={<Sell isOpen={showForm} onClose={handleCloseForm} />} />
                <Route path="/termsandcondition" element={<TermsAndConditions />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              </Routes>
              <Footer />
            </Router>
          </Suspense>
        </SnackbarProvider>
        <Snackbar open={isOpen} autoHideDuration={5000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            variant="filled"
            severity={"error"}
            sx={{ width: "100%", fontWeight: 500 }}
          >
            {snackbarMsg}
          </Alert>
        </Snackbar>
        {/* </CurrentServiceProvider> */}
      </QueryClientProvider>
      {/* </Provider> */}
    </CacheProvider>
  );
}

export default App;
