import React from "react";
import "./style.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Image } from "@mui/icons-material";
import LogoIcon from "../Icons/Logo";

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row className="footer-row">
          <Col md={3} sm={5} className="box">
            <div className="logo">
            <LogoIcon  />
              <h2 className="logo">Viraj Auto</h2>
            </div>
            <p>
              Welcome to Viraj Auto: Your Trusted Destination for Quality
              Vehicles where authenticity meets excellence on the road.
            </p>
          </Col>
          <Col md={3} sm={5} className="box">
            <h2>About Us</h2>
            <p>
              Viraj Auto is committed to providing high-quality vehicles and
              exceptional customer service. Learn more about us and our
              services.
            </p>
            <ul>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/stores">Our Stores</Link>
              </li>
              <li>
                <Link to="/termsandcondition">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/privacypolicy">Privacy Policy</Link>
              </li>
            </ul>
          </Col>
          <Col md={3} sm={5} className="box">
            <h2>Contact Us</h2>
            <ul>
              <li>Wanjarwadi, Tal - Baramati, Dist - Pune</li>
              <li>Email: virajauto@gmail.com</li>
              <li>Phone: +91 7264052222</li>
            </ul>
          </Col>
          <Col md={3} sm={5} className="box">
            <h2>Customer Care</h2>
            <p>
              We are here to assist you with any inquiries or concerns you may
              have. Contact our customer care team for support.
            </p>
            <ul>
              <li>
                <Link to="/">Help Center</Link>
              </li>
              <li>
                <Link to="/">How to Buy</Link>
              </li>
              <li>
                <Link to="/">Track Your Order</Link>
              </li>
            </ul>
          </Col>
        </Row>
        <div className="disclaimer">
          Disclaimer: This website supports the end user (vehicle owner) by
          providing information for informational purposes only. The website is
          not responsible for any undisclosed information about the vehicles.
          Users are encouraged to exercise due diligence and verify details
          independently.
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
